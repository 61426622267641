import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n

  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: document.querySelector("html").lang,
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          description: {
            err: "Number not found, re-enter numbers or contact customer service: +31203458851 / info@smartvatten.com ",
            err2: "The image with the device ID you entered could not be found. If the device ID is correct, check the phone number you entered. Press the home icon to re-enter the phone number.",
            err3: "If you do not receive a meter reading, please contact customer service to confirm your number: +31203458851 / info@smartvatten.com",
            main: "Input 4 last digits of your phone number",
            main2: "Submit",
            part1: "Enter the Smartvatten device ID shown on the device",
            part2: "SHOW METER READING",
            part3: "Image date: ",
            part4: "Image is ",
            part5: "  days old",
          },
        },
      },
      fr: {
        translation: {
          description: {
            err: "Chiffres non trouvé, veuillez réessayer ou contactez le Service Client: +31203081135 / info@smartvatten.fr ",
            err2: "L'image avec l'ID de l'appareil que vous avez saisi n'a pas pu être trouvée. Si l'ID de l'appareil est correct, vérifiez le numéro de téléphone que vous avez saisi. Appuyez sur l'icône d'accueil pour saisir à nouveau le numéro de téléphone. ",
            err3: "Si vous ne recevez pas de relevé de compteur, veuillez contacter le service clientèle pour confirmer votre numéro: +31203081135 / info@smartvatten.fr ",
            main: "Veuillez insérer les 4 derniers chiffre de votre numéro de portable",
            main2: "Soumettre",
            part1:
              "Entrez l'ID de votre appareil Smartvatten qui se trouve sur l'appareil",
            part2: " MONTREZ LE RELEVÉ DE COMPTEUR ",
            part3: "Date de l'image : ",
            part4: "L'image à ",
            part5: " jours",
          },
        },
      },
      de: {
        translation: {
          description: {
            err: " Telefonnummer wurde nicht gefunden, geben Sie die Nummer noch einmal ein, oder wenden Sie sich an den Kundendienst: +493043088000 / info@smartvatten.de.de  ",
            err2: "Die Bilddatei mit der von Ihnen eingegebenen Geräte-ID, wurde nicht gefunden. Wenn die Geräte-ID korrekt ist, überprüfen Sie die eingegebene Telefonnummer. Drücken Sie das Home-Symbol, um die Rufnummer erneut einzugeben.  ",
            err3: "Wenn Sie keinen Zählerstand erhalten, wenden Sie sich bitte an den Kundendienst, um Ihre Nummer zu bestätigen: +493043088000 / info@smartvatten.de",
            main: "Geben Sie die letzten 4 Ziffern Ihrer Telefonnummer ein",
            main2: "Abschicken",
            part1: "Geben Sie die Smartvatten-Geräte-ID ein, die auf dem Gerät angezeigt wird",
            part2: "ZÄHLERSTAND ANZEIGEN",
            part3: "Bilddatum: ",
            part4: "Bild ist ",
            part5: " Tage alt",
          },
        },
      },
      fi: {
        translation: {
          description: {
            err: "Numeroa ei löydy, syötä numerot uudestaan. Ongelman jatkuessa ota yhteys asiakaspalveluun: +358207414020 / info@smartvatten.fi",
            err2: "Kuvaa ei löydy antamallasi laite id:llä. Jos laite id on oikein, tarkista antamasi puhelinnumero. Painamalla koti-ikonia pääset syöttämään puhelinnumeron uudestaan.  ",
            err3: "Mikäli et saa mittarilukemaa, ole yhteydessä asiakaspalveluun numerosi tarkistamista varten: +358207414020 / info@smartvatten.fi",
            main: "Syötä puhelinnumeron neljä viimeistä numeroa",
            main2: "Kirjaudu sisään",
            part1: "Syötä Smartvatten laitteen id",
            part2: "NÄYTÄ MITTARILUKEMA",
            part3: "Kuva on otettu: ",
            part4: "Kuva on ",
            part5: " päivää vanha",
          },
        },
      },
      sv: {
        translation: {
          description: {
            err: "Numret hittas ej. Ange numret en gång till eller kontakta vår support: +468313000 / info@smartvatten.se ",
            err2: "Bilden hittas inte med angiven enhets ID. Om enhetens ID är korrekt, kontrollera telefonnumret du angett. Klicka på  Hem-ikonen för att ange telefonnummer på nytt. ",
            err3: "Om du inte får någon mätarställning, vänligen ta kontakt med vår kundsupport för att bekräfta ditt telefonnummer: +468313000 / info@smartvatten.se ",
            main: "Ange mobilnumrets sista 4 siffror",
            main2: "skicka",
            part1: "Ange Smartvatten ID som visas på enheten",
            part2: "Visa mätarställning",
            part3: "Bilddatum: ",
            part4: "Bilden är ",
            part5: " dagar gammal",
          },
        },
      },
      nl: {
        translation: {
          description: {
            err: "Nummer niet gevonden, voer nummers opnieuw in of neem contact op met de klantenservice: +31203081135 / info@smartvatten.nl ",
            err2: "De afbeelding met de door u ingevoerde device ID kon niet worden gevonden. Als de device ID correct is, controleer dan het telefoonnummer dat u hebt ingevoerd. Druk op de home icoon om het telefoonnummer opnieuw in te voeren. ",
            err3: "Als u geen meterstand ontvangt, neem dan contact op met de klantenservice om uw nummer te bevestigen: +31203081135 / info@smartvatten.nl ",
            main: "voer 4 laatste cijfers van uw telefoonnummer in",
            main2: "Invoeren",
            part1:
              "Voer het Smartvatten apparaat ID in die op het apparaat staat",
            part2: "Toon meterstand",
            part3: "Datum van beeld: ",
            part4: "Beeld is ",
            part5: " dag(en) oud",
          },
        },
      },
    },
  });

export default i18n;
