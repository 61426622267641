import React, { useState } from "react";
import "./Login.css";
import Button from "@material-ui/core/Button";
import { TextField } from "@material-ui/core";
import moment from "moment";
import axios from "axios";
import house from "./house.svg";
import logo3 from "./logo3.png";
import { Trans } from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import config from "./config";
import drop from "./drop.svg";
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#0060FF',
  },
}))(Tooltip);
const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;

export default function Load() {
  const [image, setImage] = useState("");
  const [times, setTimes] = useState("");
  const [age, setAge] = useState("");
  const [id, setId] = useState("");
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [text3, setText3] = useState("");
  const [id1, setid1] = useState("");
  const [historyId, setHistoryId] = useState("");
  const [historyId2, setHistoryId2] = useState("");
  const [historyId3, setHistoryId3] = useState("");
  const [historyId4, setHistoryId4] = useState("");
  const [historyId5, setHistoryId5] = useState("");
  const [historyImage, setHistoryImage] = useState("");
  const [historyImage2, setHistoryImage2] = useState("");
  const [historyImage3, setHistoryImage3] = useState("");
  const [historyImage4, setHistoryImage4] = useState("");
  const [historyImage5, setHistoryImage5] = useState("");
  axios.defaults.baseURL = config.APIDATA + "/getimage/";

  const inputChanged = (event) => {
    if (rx_live.test(event.target.value)) {
      setId(event.target.value);
    }
  };

  const getTime = () => {
    let y = sessionStorage.getItem("token");
    axios
      .get("/timestamp/" + id, {
        headers: { authorization: `${y}` },
      })
      .then((res) => {
        const allData = res.data.timestamp;
        if (!allData) {
          setText3("");
          setTimes("");
          setImage("");
          setAge("");
          displayError();
        } else {
          let day = moment(new Date(allData)).format("DD.MM.YYYY");
          let today = new Date();
          let picDay = new Date(allData);
          let diff = today - picDay;
          let days = Math.ceil(diff / (1000 * 3600 * 24)) - 1;
          setTimes(day);
          setAge(days);
        }
      });
  };

  const getImage = () => {
    let y = sessionStorage.getItem("token");
    axios
      .get("/image/" + id, {
        headers: { authorization: `${y}` },
        responseType: "blob",
      })
      .then((response) => {
        let freeder = new window.FileReader();
        freeder.readAsDataURL(response.data);
        freeder.onload = function () {
          var image = freeder.result;
          if (image === null) {
            setText3("");
            setTimes("");
            setAge("");
            displayError();
          } else {
            setImage(<img src={image} alt="" width="200" />);
          }
        };
      });
  };

  function displayError() {
    let errorTxt1 = <Trans i18nKey="description.err2"></Trans>;
    let errorTxt2 = <Trans i18nKey="description.err3"></Trans>;
    setText1(errorTxt1);
    setText2(errorTxt2);
    setText3("");
    setTimes("");
    setImage(
      <center>
        <img src={drop} alt="" width="20" />{" "}
      </center>
    );
    setAge("");
  }

  function onclick() {
    if (id.length === 6) {
      getTime();
      getImage();
      displayQuestion();
      refreshId();
      createItem();
    }
  }

  const [first, setFirst] = useState("");
  const [frame, setFrame] = useState("");
  const [frame1, setFrame1] = useState("");
  const [frame2, setFrame2] = useState("");
  const [frame3, setFrame3] = useState("");
  const [frame4, setFrame4] = useState("");

  function refreshPage() {
    window.location.reload();
  }
  function home() {
    sessionStorage.clear();
    refreshPage();
  }

  function createItem() {
    setHistoryId(id1);
    setHistoryImage(image);
    sessionStorage.setItem([historyId], [historyImage]);
    if (id1) {
      setFrame(
        <div class="outer">
          <div>
            <p>{id1}</p>
            <p>{image}</p>
          </div>
        </div>
      );
    }

    sessionStorage.getItem([historyId], [historyImage]);
    if (historyId) {
      setFrame1(
        <div class="outer">
          <div>
            <p>{historyId}</p>
            <p>{historyImage}</p>
          </div>
        </div>
      );
    }

    setHistoryImage2(historyImage);
    setHistoryId2(historyId);
    sessionStorage.setItem([historyId2], [historyImage2]);
    sessionStorage.getItem([historyId2], [historyImage2]);
    if (historyId2) {
      setFrame2(
        <div class="outer">
          <div>
            <p>{historyId2}</p>
            <p>{historyImage2}</p>
          </div>
        </div>
      );
    }
    setHistoryImage3(historyImage2);
    setHistoryId3(historyId2);
    sessionStorage.setItem([historyId3], [historyImage3]);
    sessionStorage.getItem([historyId3], [historyImage3]);
    if (historyId3) {
      setFrame3(
        <div class="outer">
          <div>
            <p>{historyId3}</p>
            <p>{historyImage3}</p>
          </div>
        </div>
      );
    }
    setHistoryImage4(historyImage3);
    setHistoryId4(historyId3);
    sessionStorage.setItem([historyId4], [historyImage4]);
    sessionStorage.getItem([historyId4], [historyImage4]);
    if (historyId4) {
      setFrame4(
        <div class="outer">
          <div>
            <p>{historyId4}</p>
            <p>{historyImage4}</p>
          </div>
        </div>
      );
    }
  }

  const displayQuestion = () => {
    let txt1 = <Trans i18nKey="description.part3"></Trans>;
    let txt2 = <Trans i18nKey="description.part4"></Trans>;
    let txt3 = <Trans i18nKey="description.part5"></Trans>;
    let id1 = <>ID: {id}</>;
    setText1(txt1);
    setText2(txt2);
    setText3(txt3);
    setid1(id1);
  };
  function refreshId() {
    setId("");
  }
  return (
    <div>
      <div className="page-container">
        <header
          className="App-header"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{
                width: "5%",
                height: "auto",
                marginTop: "10px",
                marginBottom: "10px",
              }}
              src={logo3}
              alt="logo"
            />
          </div>
        </header>
        <center>
          <div
            className="content-wrap"
            display="flex"
            width="100vw"
            height="100vh"
            fontSize="20px"
          >
            <br></br><CustomTooltip title="Home">
            <IconButton
              onClick={home}
              color="primary"
              variant="contained"
              size="very small"
            >
              <img
                src={house}
                alt="Home"
                style={{ width: "30px", height: "30px" }}
              />
            </IconButton></CustomTooltip>
            <br />
            <br />
            <h4>
              <p>
                <Trans i18nKey="description.part1"></Trans>
              </p>
            </h4>
            <TextField
              id="outlined-basic"
              variant="standard"
              InputProps={{ disableUnderline: true }}
              inputProps={{
                maxLength: 6,
                style: {
                  backgroundColor: "#FFFFFF",
                  borderRadius: "8px",
                  color: "#002458",
                  padding: "8px",
                  border: "1px solid rgba(0, 36, 88, 0.6)",
                  width: "150px",
                  height: "30px",
                  margin: "40px auto 0",
                  fontFamily: "Montserrat",
                  fontStyle: "normal",
                  fontSize: "20px",
                  lineHeight: "normal",
                  textAlign: "center",
                  display: "block",
                  boxShadow: "none",
                },
              }}
              pattern="[+-]?\d+(?:[.,]\d+)?"
              onChange={inputChanged}
              value={id}
              autoFocus
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  onclick(event);
                  event.preventDefault();
                }
              }}
            />
           
            <br></br>
            <br></br>
            <Button
              onClick={onclick}
              color="primary"
              variant="contained"
              size="small"
              style={{
                backgroundColor: "#002458",
                borderRadius: "20px",
                color: "#FFFFFF",
                padding: "8px 16px",
                border: "none",
                cursor: "pointer",
                fontFamily: "Montserrat",
                fontStyle: "medium",
                fontSize: "15px",
                lineHeight: "100%",
                textAlign: "center",
                letterSpacing: "-2%",
                margin: "15px",
              }}
            >
              <Trans i18nKey="description.part2"></Trans>
            </Button>
          </div>
          <br></br>
          <div className="item">{id1}</div>
      <div >{text1}{times}</div>
      <div>{text2}{age}{text3}<div><br/>{image}</div></div>
      
      <div className="centered">
        <p>{frame}</p>
            <p>{frame1}</p>
            <p>{frame2}</p>
            <p>{frame3}</p>
            <p>{frame4}</p>
          </div>
          <div class="push"></div>
        </center>
      </div>
      <footer class="footer"></footer>
    </div>
  );
}
