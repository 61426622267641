import React, { useState } from "react";
import "./Login.css";
import PropTypes from "prop-types";
import logo3 from "./logo3.png";
import Button from "@material-ui/core/Button";
import i18n from "./i18n";
import { Trans } from "react-i18next";
import { TextField } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import deu from "./flags/deu.svg";
import eng from "./flags/eng.svg";
import fin from "./flags/fin.svg";
import fra from "./flags/fra.svg";
import nel from "./flags/nel.svg";
import sve from "./flags/sve.svg";
import us from "./flags/us.svg";
import config from "./config.js";

const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;

const lngs = {
  en: { nativeName: <img src={eng} width="20" alt="error" /> },
  fi: { nativeName: <img src={fin} width="20" alt="error" /> },
  sv: { nativeName: <img src={sve} width="20" alt="error" /> },
  nl: { nativeName: <img src={nel} width="20" alt="error" /> },
  fr: { nativeName: <img src={fra} width="20" alt="error" /> },
  de: { nativeName: <img src={deu} width="20" alt="error" /> },
};

async function loginUser(userPin) {
  return fetch(config.APIDATA + "/getphone/" + userPin, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((data) => data.json());
}

export default function Login(props) {
  const [userPin, setUserPin] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userPin.length === 4) {
      const token = await loginUser(userPin);
      props.setToken(token);
    }
  };

  const inputChanged = (e) => {
    if (rx_live.test(e.target.value)) {
      setUserPin(e.target.value);
    }
  };

  function refreshPage() {
    window.location.reload();
  }

  return (
    <div className="App">
      <center>
        <header
          className="App-header"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{
                width: "5%",
                height: "auto",
                marginTop: "10px",
                marginBottom: "10px",
              }}
              src={logo3}
              alt="logo"
            />
          </div>
        </header>

        <br></br>
        <p>Select language</p>
        <br />

        {Object.keys(lngs).map((lng) => (
          <IconButton
            key={lng}
            style={{
              margin: "10px",
              backgroundColor: "#F2F2F2",
              size: "small",
            }}
            onClick={() => {
              i18n.changeLanguage(lng);
              refreshPage();
            }}
          >
            {lngs[lng].nativeName}
          </IconButton>
        ))}

        <h4>
          <p>
            <Trans i18nKey="description.main"></Trans>
          </p>
        </h4>
        <TextField
          id="outlined-basic"
          variant="standard" 
          type="password"
          InputProps={{ disableUnderline: true }}
          inputProps={{
            maxLength: 4,
            style: {
              backgroundColor: "#FFFFFF",
              borderRadius: "8px",
              color: "#002458",
              padding: "8px",
              border: "1px solid rgba(0, 36, 88, 0.6)",
              width: "100px",
              height: "30px",
              margin: "40px auto 0", 
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontSize: "18px",
              lineHeight: "normal",
              textAlign: "center",
              display: "block", 
              boxShadow: "none", 
            },
          }}
          pattern="[+-]?\d+(?:[.,]\d+)?"
          onChange={inputChanged}
          value={userPin}
          autoFocus
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              handleSubmit(event);
              event.preventDefault();
            }
          }}
        />
        <div>
          <br></br>
          <br></br>
          <center>
            <Button
              onClick={handleSubmit}
              type="submit"
              style={{
                backgroundColor: "#002458",
                borderRadius: "20px",
                color: "#FFFFFF",
                padding: "8px 16px",
                border: "none",
                cursor: "pointer",
                fontFamily: "Montserrat",
                fontStyle: "medium",
                fontSize: "16px",
                lineHeight: "100%",
                textAlign: "center",
                letterSpacing: "-2%",
                margin: "10px",
              }}
              variant="contained"
              size="small"
            >
              <Trans i18nKey="description.main2"></Trans>
            </Button>
          </center>
          <div class="push"></div>
        </div>
      </center>
      <footer class="footer"></footer>
    </div>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
