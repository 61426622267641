import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Images from "./Images";
import Login from "./Login";
import Login2 from "./Login2";
import "./Login.css";
import React from "react";
import useToken from "./useToken";
var version = require("../package.json").version

function App() {
 console.log(version);
  let {token, setToken } = useToken();
  if(token === false)
    {
      return <Login2 setToken={setToken} />
    }
  else if (!token)  {
    return <Login setToken={setToken} />;
  }
  return (
    
    <div className="App">
     
        <Router>
          <Switch>
            <Route path="/" >
            <Images />
            </ Route>
          </Switch>
        </Router>
        
    </div>
  );
}

export default App;
